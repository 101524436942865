<template>
  <div class="container">
    <bict-header/>
    <div class="bict-banner responsive-container">
      <swiper :options="swiperOption" ref="mySwiper">
        <swiper-slide v-for="(item, index) in imgurl" :key="index">
          <!-- <img ref="image" :src="item.imageUrl" style="width:100%" alt=""> -->
          <img ref="image" src="../assets/icon/banar01.png" style="width:100%" alt="">
        </swiper-slide>
        <swiper-slide v-for="(item, index) in imgurl" :key="index">
          <img ref="image" src="../assets/icon/banar02.png" style="width:100%" alt="">
        </swiper-slide>
        <swiper-slide v-for="(item, index) in imgurl" :key="index">
          <img ref="image" src="../assets/icon/banar03.png" style="width:100%" alt="">
        </swiper-slide>
<!--        <swiper-slide v-for="(item, index) in imgurl" :key="index">-->
<!--          &lt;!&ndash; <img ref="image" :src="item.imageUrl" style="width:100%" alt=""> &ndash;&gt;-->
<!--          <img ref="image" src="../assets/icon/banar04.png" style="width:100%" alt="">-->
<!--        </swiper-slide>-->
<!--        <swiper-slide v-for="(item, index) in imgurl" :key="index">-->
<!--          <img ref="image" src="../assets/icon/banar05.png" style="width:100%" alt="">-->
<!--        </swiper-slide>-->
<!--        <swiper-slide v-for="(item, index) in imgurl" :key="index">-->
<!--          <img ref="image" src="../assets/icon/banar06.png" style="width:100%" alt="">-->
<!--        </swiper-slide>-->
      </swiper>
    </div>
    <div class="bict-notice responsive-container">
      <div class="bict-notice-icon">
        <img src="../assets/icon/icon_notice.png" alt="" class="icon">
      </div>
      <div class="bict-notice-nav">
        <ul class="bict-ul">
          <li class="bict-li" v-if="noticeList.length > 0" @click="handleNotice(noticeList[0])">
            <p class="text">{{ noticeList[0].title }}</p>
          </li>
          <li class="bict-li" v-if="noticeList.length > 1">
            <span class="bict-li-hr">/</span>
          </li>
          <li class="bict-li" v-if="noticeList.length > 1" @click="handleNotice(noticeList[1])">
            <p class="text">{{ noticeList[1].title }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="bict-rank responsive-container">
      <div class="bict-rank-title">
        <h3 class="bict-h3">{{$t('Top Trend Rankings')}}</h3>
      </div>
      <div class="bict-rank-category">
        <ul class="bict-ul">
          <li class="bict-li " :class="rankType === 1 ? 'selected' : ''" @click="handleRank(1)">
            <span class="text">{{ $t('Top Gainers') }}</span>
          </li>
          <li class="bict-li" :class="rankType === 2 ? 'selected' : ''" @click="handleRank(2)">
            <span class="text">{{ $t('All Quotes') }}</span>
          </li>
        </ul>
      </div>
      <div class="bict-table hidden-sm-and-down">
        <div class="bict-table-header">
          <div class="bict-table-tr">
            <div class="bict-table-th">
              <span class="text">{{ $t('Trading Pair') }}</span>
            </div>
            <div class="bict-table-th">
              <span class="text">{{ $t('Latest Price') }}</span>
            </div>
            <div class="bict-table-th">
              <span class="text">{{ $t('Increase/Decrease') }}</span>
            </div>
            <div class="bict-table-th">
              <span class="text">{{ $t('Highest Price') }}</span>
            </div>
            <div class="bict-table-th">
              <span class="text">{{ $t('Lowest Price') }}</span>
            </div>
            <div class="bict-table-th">
              <span class="text">{{ $t('Trading Volume') }}</span>
            </div>
            <div class="bict-table-th">
              <span class="text">{{ $t('TransactionValue') }}</span>
            </div>
          </div>
        </div>
        <div class="bict-table-body" v-loading="marketLoad">
          <div class="bict-table-tr" v-for="(item,index) in marketList" :key="index">
            <div class="bict-table-td">
              <div class="bict-table-icon">
                <!--                <img src="../assets/icon/icon_first.png" alt="" class="icon">-->
              </div>
              <div class="bict-table-title">
                <p class="host">{{ item.symbol }}</p>
                <p class="sub">{{ item.name }}</p>
              </div>
            </div>
            <div class="bict-table-td">
              <div class="bict-table-title">
                <p class="host">{{ item.price }}</p>
                <p class="sub">{{ item.priceCny }}</p>
              </div>
            </div>
            <div class="bict-table-td">
              <p class="text" :class="item.rate < 0 ? 'drop' : 'rise'">{{
                  item.rate >= 0 ? '+' + item.rate : item.rate
                }}%</p>
            </div>
            <div class="bict-table-td">
              <p class="text">{{ item.high }}</p>
            </div>
            <div class="bict-table-td">
              <p class="text">{{ item.low }}</p>
            </div>
            <div class="bict-table-td">
              <p class="text">{{ item.amount }}</p>
            </div>
            <div class="bict-table-td">
              <p class="text">{{ item.balance }}</p>
            </div>
          </div>
        </div>
<!--        <div class="bict-table-more" @click="handleNext">-->
<!--          <p class="text">查看更多行情</p>-->
<!--          <img src="../assets/icon/icon_more.png" alt="" class="icon">-->
<!--        </div>-->
      </div>

      <div class="bict-trend-rank hidden-sm-and-up" v-loading="marketLoad">
        <div class="bict-trend-rank-item" v-for="(item, index) in marketList" :key="index">
          <div class="bict-trend-rank-item-header">
            <div class="bict-trend-rank-item-name">{{item.name}}</div>
            <div class="bict-trend-rank-item-symbol">{{item.symbol}}</div>
          </div>
          <div class="bict-trend-rank-item-body">
            <div class="bict-trend-rank-item-body-info-item">
              <div class="bict-trend-rank-item-body-info-item-value">
                <div class="bict-trend-rank-item-body-info-item-value-main">{{item.price}}</div>
                <div class="bict-trend-rank-item-body-info-item-value-sub">{{ item.priceCny }}</div>
              </div>
              <div class="bict-trend-rank-item-body-info-item-name">{{ $t('Latest Price') }}</div>
            </div>
            <div class="bict-trend-rank-item-body-info-item">
              <div class="bict-trend-rank-item-body-info-item-value">
                <span class="text" :class="item.rate < 0 ? 'drop' : 'rise'">{{
                  item.rate >= 0 ? '+' + item.rate : item.rate
                }}%</span>
              </div>
              <div class="bict-trend-rank-item-body-info-item-name">{{ $t('Increase/Decrease') }}</div>
            </div>
            <div class="bict-trend-rank-item-body-info-item">
              <div class="bict-trend-rank-item-body-info-item-value">{{item.high}}</div>
              <div class="bict-trend-rank-item-body-info-item-name">{{ $t('Highest Price') }}</div>
            </div>
            <div class="bict-trend-rank-item-body-info-item">
              <div class="bict-trend-rank-item-body-info-item-value">{{item.low}}</div>
              <div class="bict-trend-rank-item-body-info-item-name">{{ $t('Lowest Price') }}</div>
            </div>
            <div class="bict-trend-rank-item-body-info-item">
              <div class="bict-trend-rank-item-body-info-item-value">{{item.amount}}</div>
              <div class="bict-trend-rank-item-body-info-item-name">{{ $t('Trading Volume') }}</div>
            </div>
            <div class="bict-trend-rank-item-body-info-item">
              <div class="bict-trend-rank-item-body-info-item-value">{{item.balance}}</div>
              <div class="bict-trend-rank-item-body-info-item-name">{{ $t('TransactionValue') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bict-advert responsive-container">
      <div class="bict-advert-title">
        <h3 class="bict-h3">{{ $t('The world leading digital asset trading platform') }}</h3>
      </div>
      <div class="bict-advert-nav">
        <ul class="bict-ul">
          <li class="bict-li">
            <div class="bict-advert-img">
              <img src="../assets/icon/icon_advert_1.png" alt="" class="image">
            </div>
            <div class="bict-advert-num">
              <p class="text">1200+</p>
            </div>
            <div class="bict-advert-note">
              <p class="text">{{ $t('Continuous safe running (day)') }}</p>
            </div>
          </li>
          <li class="bict-li">
            <div class="bict-advert-img">
              <img src="../assets/icon/icon_advert_2.png" alt="" class="image">
            </div>
            <div class="bict-advert-num">
              <p class="text">{{$t('180billion')}}+</p>
            </div>
            <div class="bict-advert-note">
              <p class="text">{{ $t('Cumulative trading volume') }}</p>
            </div>
          </li>
          <li class="bict-li">
            <div class="bict-advert-img">
              <img src="../assets/icon/icon_advert_3.png" alt="" class="image">
            </div>
            <div class="bict-advert-num">
              <p class="text">200W+</p>
            </div>
            <div class="bict-advert-note">
              <p class="text">{{$t('Cumulative service customers')}}</p>
            </div>
          </li>
          <li class="bict-li">
            <div class="bict-advert-img">
              <img src="../assets/icon/icon_advert_4.png" alt="" class="image">
            </div>
            <div class="bict-advert-num">
              <p class="text">600+</p>
            </div>
            <div class="bict-advert-note">
              <p class="text">{{$t('Cumulative franchise trader')}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
<!--    <div class="bict-image responsive-container">-->
<!--&lt;!&ndash;      <img src="../assets/icon/icon_image.png" alt="" class="image">&ndash;&gt;-->
<!--      <div class="bict-image-text">-->
<!--        <h3 class="bict-image-text1">{{ $t('Intelligent one click tracking without staring at the disk') }}</h3>-->
<!--        <h3 class="bict-image-text2">{{ $t('Analyze the market at any time and easily grasp the dynamics') }}</h3>-->
<!--      </div>-->
<!--    -->
<!--      <div class="bict-image-row">-->
<!--        <img src="../assets/icon/icon_image_1.png" alt="" class="image2">-->
<!--        <h3 class="bict-image-row-h3">{{ $t('No contact with user account') }}</h3>-->
<!--      </div>-->
<!--      <div class="bict-image-row">-->
<!--        <img src="../assets/icon/icon_image_2.png" alt="" class="image2">-->
<!--        <h3 class="bict-image-row-h3">{{ $t('Free combination merchandising') }}</h3>-->
<!--      </div >-->
<!--      <div class="bict-image-row">-->
<!--        <img src="../assets/icon/icon_image_3.png" alt="" class="image2">-->
<!--        <h3 class="bict-image-row-h3">{{ $t('Real time Merchandising / stop merchandising') }}</h3>-->
<!--      </div>-->
<!--      <div class="bict-image-row">-->
<!--        <img src="../assets/icon/icon_image_4.png" alt="" class="image2">-->
<!--        <h3 class="bict-image-row-h3">{{ $t('Gold brokerage information is updated in real time without data fraud') }}</h3>-->
<!--      </div>-->
<!--    </div>-->
    <div class="bict-platform responsive-container">
      <div class="bict-platform-title">
        <h3 class="bict-h3">{{ $t('Anytime, anywhere Real-time view Multi-platform terminal trading') }}</h3>
        <h3 class="bict-sub">{{ $t('Download Android or iOS mobile, PC client and trade/recharge anytime, anywhere') }}</h3>
      </div>
      <el-row class="bict-platform-box" :gutter="20">
        <el-col :md="12" :sm="24" class="bict-platform-left">
          <div class="bict-platform-note">
<!--            <div class="bict-platform-nav">-->
<!--              <img src="../assets/icon/icon_platform_bind.png" alt="" class="icon">-->
<!--              <p class="text">{{ $t('Binding gold medal') }}</p>-->
<!--              <p class="note">{{ $t('Brokerage one click automatic documentary No need to stare at the plate') }}</p>-->
<!--            </div>-->
            <div class="bict-platform-nav">
              <img src="../assets/icon/icon_platform_quick.png" alt="" class="icon">
              <p class="text">{{ $t('Convenient and fast') }}</p>
              <p class="note">{{ $t('Trading anytime, anywhere, safe and secure') }}</p>
            </div>
            <div class="bict-platform-nav">
              <img src="../assets/icon/icon_platform_any.png" alt="" class="icon">
              <p class="text">{{ $t('Trade at any time') }}</p>
              <p class="note">{{ $t('The market trend switches freely and is clear at a glance') }}</p>
            </div>
          </div>
          <div class="bict-platform-buttons">
            <div class="bict-platform-button">
              <button class="bict-btn bict-btn-download" @click="handleDownload('ios')">
                <img src="../assets/icon/icon_download_ios.png" alt="" class="icon">
                <span class="text">DOWNLOAD IOS</span>
              </button>
            </div>
            <div class="bict-platform-button">
              <button class="bict-btn bict-btn-download" @click="handleDownload('apk')">
                <img src="../assets/icon/icon_download_android.png" alt="" class="icon">
                <span class="text">DOWNLOAD APK</span>
              </button>
            </div>
<!--            <div class="bict-platform-button">-->
<!--              <button class="bict-btn bict-btn-download" @click="handleDownload('pc')">-->
<!--                <img src="../assets/icon/icon_download_pc.png" alt="" class="icon">-->
<!--                <span class="text">DOWNLOAD PC</span>-->
<!--              </button>-->
<!--            </div>-->
          </div>
        </el-col>
        <el-col :md="12" :sm="24" class="bict-platform-right">
          <img src="../assets/icon/icon_phone.png" alt="" class="image">
        </el-col>
      </el-row>
    </div>

<!--    首页下载-->
    <bict-bottom/>
  </div>
</template>

<script>
import BictHeader from "../components/BictHeader";
import BictBottom from "../components/BictBottom";
import {ProcoinArticle, ProcoinMarket} from "../api";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

export default {
  name: "index",
  components: {
    BictHeader,
    BictBottom,
    swiper,
    swiperSlide
  },
  data () {
    return {
      imgurl: [{'imageUrl': "banar.jpg"}],
      marketLoad: true,
      marketForm: {
        tab: "stock",
        sortField: "rate",
        sortType: 1,
      },
      rankType: 1,
      noticeList: [],
      marketList: [],
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      }
    }
  },
  created () {
    this.init();
    this.procoinMarket();
    // this.getBanners();
  },
  methods: {
    // getBanners() {
    //   getBanners().then(res => {
    //     if (res.code == 200) {
    //       // this.imgurl = res.data.banner;
    //     }
    //   });
    // },
    init () {
      const params = {
        pageNo: 1,
        pageSize: 2,
        lang: "tw"
      }
      ProcoinArticle(params).then(res => {
        if (res.code == 200) {
          this.noticeList = res.data.data
        }
      })
    },
    handleRank (event) {
      if (event === 1) {
        this.marketForm = {
          tab: "stock",
          sortField: "rate",
          sortType: 1,
        }
      } else {
        this.marketForm = {}
      }
      this.rankType = event
      this.procoinMarket()
    },
    handleNext () {
      this.params.pageNo = this.params.pageNo + 1
      this.init()
    },
    procoinMarket () {
      this.marketLoad = true
      const params = Object.assign({}, this.marketForm);
      params.lang = "tw";
      ProcoinMarket(params).then(res => {
        if (res.code === 200) {
          this.marketList = res.data.quotes;
          this.marketLoad = false
        }
      })
    },
    handleDownload (type) {
      let url;
      if (type === "ios") {
        url = "http://fen.asliyueoscc.icu/zti";
      } else if (type === "apk") {
        url = "http://fen.asliyueoscc.icu/zti";
      } else {
        url = "http://fen.asliyueoscc.icu/zti";
      }
      window.open(url, "_blank")
    },
    handleNotice (event) {
      this.$router.push({
        path: '/notice/content/' + event.articleId,
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.bict-trend-rank {
  margin-top: 40px;

  .bict-trend-rank-item {
    margin-bottom: 5px;
    background: #fff;
    border-radius: 3px;

    .bict-trend-rank-item-header {
      display: flex;
      align-items: center;
      padding: 20px;

      .bict-trend-rank-item-name {
        font-size: 16px;
        font-weight: bold;
        color: black;
      }
      .bict-trend-rank-item-symbol {
        font-size: 16px;
        color: #7E8EA1;
        margin-left: 10px;
      }
    }

    .bict-trend-rank-item-body {
      display: flex;
      flex-wrap: wrap;
      padding: 0 20px;

      .bict-trend-rank-item-body-info-item {
        width: calc(100% / 3);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        height: 60px;

        .bict-trend-rank-item-body-info-item-value {
          color: black;
          margin-bottom: 5px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .bict-trend-rank-item-body-info-item-value-main {

          }

          .bict-trend-rank-item-body-info-item-value-sub {
            font-size: 12px;
            font-family: FZHei-B01T;
            font-weight: 400;
            color: #7E8EA1;
          }

          .drop {
            color: #F8739F;
          }

          .rise {
            color: #43EC85;
          }
        }

        .bict-trend-rank-item-body-info-item-name {
          font-size: 12px;
          color: #000;
        }
      }
    }
  }
}
.container {
  background: #fff;
}

@media screen and (min-width: 1200px) {
  .responsive-container {
    width: 1000px;
  }
}

@media screen and (max-width: 768px) {
  .bict-image {
    margin-top: 20px !important;
  }
  .bict-platform {
    margin-top: 20px !important;
  }
  .bict-notice {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .bict-ul {
      justify-content: center;
    }
  }

  .bict-advert {
    .bict-ul {
      flex-wrap: wrap;
      .bict-li {
        width: 50%;
        flex: none !important;
        margin-bottom: 20px;
      }
    }
  }
  .bict-platform {

    .bict-platform-box {
      margin-top: 40px !important;

      .bict-platform-left {
        .bict-platform-note {
          .bict-platform-nav {
            .text {
              font-size: 14px !important;
            }
            .note {
              font-size: 14px !important;
            }
          }
        }
      }
      .bict-platform-right {
        .image {
          width: 100% !important;
          height: auto !important;
        }
      }
    }
  }
}

.bict-banner {
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.bict-notice {
  margin: 70px auto auto;
  padding: 0 18px;
  overflow: hidden;
  display: flex;

  .bict-notice-icon {
    .icon {
      width: 18px;
      height: 20px;
    }
  }

  .bict-notice-nav {
    .bict-ul {
      padding: 0;
      margin: 0 0 0 20px;
      display: flex;
      justify-content: space-between;

      .bict-li {
        list-style: none;
        margin-right: 20px;
        line-height: 20px;
        cursor: pointer;

        .text {
          font-size: 14px;
          font-family: FZHei-B01T;
          font-weight: 400;
          margin: 0;
          color: #AFB1B4;
        }

        .bict-li-hr {
          font-size: 14px;
          font-family: FZHei-B01T;
          font-weight: 400;
          color: #AFB1B4;
        }
      }
    }
  }
}

.bict-rank {
  margin: 80px auto auto;
  overflow: hidden;
  padding: 0 18px;

  .bict-rank-title {
    .bict-h3 {
      margin: 0;
      font-size: 25px;
      font-family: FZHei-B01T;
      font-weight: 400;
      color: #000;
    }

  }

  .bict-rank-category {
    margin-top: 25px;

    .bict-ul {
      margin: 0;
      padding: 0;

      .bict-li {
        list-style: none;
        display: inline-block;
        padding: 10px 15px;
        margin-right: 20px;
        cursor: pointer;

        .text {
          font-size: 14px;
          font-family: FZHei-B01T;
          font-weight: 400;
          color: #000;
        }

        &.selected {
          background: #13233D;
          border: 1px solid #009CFA;
          border-radius: 18px;

          .text {
            font-size: 14px;
            font-family: FZHei-B01T;
            font-weight: 400;
            color: #009CFA;
          }
        }
      }
    }
  }

  .bict-table {
    margin-top: 30px;
    width: 100%;

    .bict-table-header {
      width: 100%;

      .bict-table-tr {
        display: flex;

        .bict-table-th {
          flex: 1;
          padding: 10px 0;
          text-align: center;

          .text {
            font-size: 14px;
            font-family: FZHei-B01T;
            font-weight: 400;
            color: #748496;
          }
        }
      }
    }

    .bict-table-body {
      min-height: 400px;

      .bict-table-tr {
        display: flex;
        margin-top: 7px;
        height: 70px;
        background: #fff;
        border-radius: 3px;

        &:hover {
          background: #05365C;
        }

        .bict-table-td {
          flex: 1;
          text-align: center;
          display: flex;
          height: 100%;
          align-items: center;
          justify-content: center;

          .text {
            margin: 0;
            font-size: 14px;
            font-family: FZHei-B01T;
            font-weight: 400;
            color: #000;

            &.drop {
              color: #F8739F;
            }

            &.rise {
              color: #43EC85;
            }
          }

          .bict-table-icon {
            height: 100%;
            display: flex;
            align-items: center;
            margin-right: 15px;

            .icon {
              width: 26px;
              height: 26px;
            }
          }

          .bict-table-title {
            text-align: left;

            .host {
              margin: 0;
              font-size: 14px;
              font-family: FZHei-B01T;
              font-weight: 400;
              color: #000;
            }

            .sub {
              margin: 0;
              font-size: 12px;
              font-family: FZHei-B01T;
              font-weight: 400;
              color: #7E8EA1;
            }
          }

          &:first-child {
            justify-content: left;

            .bict-table-icon {
              margin-left: 30px;
            }
          }
        }


      }
    }

    .bict-table-more {
      margin-top: 60px;
      display: flex;
      align-items: center;
      justify-content: center;

      .text {
        font-size: 14px;
        font-family: FZHei-B01T;
        font-weight: 400;
        color: #009CFA;
      }

      .icon {
        width: 6px;
        height: 12px;
        margin-left: 16px;
      }
    }
  }
}

.bict-advert {
  margin: 80px auto auto;
  overflow: hidden;
  padding: 0 18px;

  .bict-advert-title {
    text-align: center;

    .bict-h3 {
      font-size: 25px;
      margin: 0;
      font-family: FZHei-B01T;
      font-weight: 400;
      color: #000;
    }
  }

  .bict-advert-nav {
    margin-top: 80px;

    .bict-ul {
      margin: 0;
      padding: 0;
      display: flex;

      .bict-li {
        list-style: none;
        flex: 1;
        text-align: center;

        .bict-advert-img {
          .image {
            width: 99px;
            height: 104px;
          }
        }

        .bict-advert-num {
          margin-top: 20px;

          .text {
            font-size: 22px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #000;
            margin-right: 10px;
          }
        }

        .bict-advert-note {
          margin-top: 10px;

          .text {
            margin-right: 10px;
            font-size: 18px;
            font-family: FZHei-B01T;
            font-weight: 400;
            color: #7D848D;
          }
        }
      }
    }
  }

}

.bict-image {
  background-image:url("../assets/icon/icon_image.png");
  background-size: cover;
  margin: 200px auto auto auto;
  offset: center;
  padding: 150px 0 150px 0;
  overflow: hidden ;
  //.image {
  //  width: 100%;
  //  height: auto;
  //}
  .bict-image-text{
    .bict-image-text1{
          margin-right: 35px !important;
          text-align: center !important;
          font-size: 17px !important;
          font-family: PingFang SC;
          color: #000;
    }
    .bict-image-text2{
          margin-top: 8px !important;
          margin-right: 35px !important;
          text-align: center;
          font-size: 11px !important;
          font-family: PingFang SC;
          color: #A9B7FF;
    }
  }
  .bict-image-row{
    margin-top: 13px !important;
    margin-left: 26px !important;
      .image2 {
        height: 15px !important;
        width: 15px !important;
      }
     .bict-image-row-h3{
          display: inline-block;
          margin-left: 15px !important;
          font-size: 15px !important;
          font-family: PingFang SC;
          color: #000;
      }
    }

}

.bict-platform {
  margin: 100px auto auto;
  overflow: hidden;
  padding: 0 18px;

  .bict-platform-title {
    text-align: center;

    .bict-h3 {
      font-size: 25px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #000;
    }

    .bict-sub {
      margin-top: 8px;
      font-size: 14px;
      font-family: FZHei-B01T;
      font-weight: 400;
      color: #7D848D;
    }
  }

  .bict-platform-box {
    margin-top: 100px;

    .bict-platform-left {
      .bict-platform-note {
        .bict-platform-nav {
          margin-bottom: 15px;
          display: flex;
          justify-content: flex-start;

          .icon {
            width: 23px;
            height: 23px;
          }

          .text {
            margin-left: 13px;
            font-size: 18px;
            font-family: FZHei-B01T;
            font-weight: bold;
            color: #000;
          }

          .note {
            margin-left: 25px;
            font-size: 18px;
            font-family: FZHei-B01T;
            color: #000;
          }
        }
      }

      .bict-platform-buttons {
        margin-top: 40px;
        width: 100%;

        .bict-platform-button {
          text-align: center;
          margin-bottom: 11px;

          .bict-btn-download {
            width: 240px;
            height: 55px;
            background: linear-gradient(90deg, #112541, #1C3455);
            border-radius: 28px;
            padding-left: 40px;
            box-sizing: border-box;

            .icon {
              float: left;
            }

            .text {
              float: left;
              font-size: 16px;
              line-height: 24px;
              display: inline-block;
              font-family: FZHei-B01T;
              font-weight: 400;
              margin-left: 14px;
              color: #FFFFFF;
            }
          }
        }
      }
    }

    .bict-platform-right {
      .image {
        width: 555px;
        height: 384px;
      }
    }

  }
}

</style>
<style>
.el-carousel__button {
  width: 8px;
  height: 8px;
  background: #2F3A49;
  border-radius: 50%;
}

.el-carousel__indicator--horizontal.is-active button {
  background: #009CFA;
}

.el-carousel__indicators--outside {
  margin-top: 24px;
}
</style>
